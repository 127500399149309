import { notifyError } from "@lib/helper";
import { L } from "@lib/abpUtility";
import http from "@services/httpService";
import { PagedResultDto } from "@services/dto/pagedResultDto";

class CustomerService {
  public async get(id: number): Promise<any> {
    if (!id) {
      notifyError(L("ERROR"), L("ENTITY_NOT_FOUND"));
    }

    const res = await http.get("api/services/app/Customers/Get", {
      params: { id },
    });

    return res.data.result;
  }

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/Customers/GetAll", { params });
    const result = res.data.result;

    return result;
  }

  public async activateOrDeactivate(id: number, isActive) {
    const result = await http.post(
      "api/services/app/Customers/Active",
      { id },
      { params: { isActive } }
    );
    return result.data;
  }
}

export default new CustomerService();
