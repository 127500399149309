import React, { CSSProperties } from "react";

export const FilterIcon = (props) => <img src="/assets/icons/filter.svg" />;
export const ExcelIcon = (props) => <img src="/assets/icons/excel.svg" />;
export const PdfIcon = (props) => <img src="/assets/icons/pdf.svg" />;
export const WordIcon = (props) => <img src="/assets/icons/word.svg" />;

export const PowerPointIcon = (props) => (
  <img src="assets/icons/power-point.svg" />
);
export const ImageIcon = (props) => (
  <img src="/assets/icons/image-file.svg" className={props?.imageClass} />
);
export const OtherFileIcon = (props) => (
  <img src="/assets/icons/other-file.svg" />
);
export const BuildingIcon = (props) => <img src="/assets/icons/.svg" />;

export const SiteVisitIcon = (props) => (
  <img src="./assets/icons/siteVisit.svg" />
);

export const ViewIcon = (props) => <img src="/assets/icons/view.svg" />;

export const DeleteIcon = (props) => <img src="/assets/icons/trash.svg" />;

export const ActiveIcon = (props) => <img src="/assets/icons/activeIcon.svg" />;

export const DownFile = (props) => <img src="/assets/icons/downfile.svg" />;

export const EditFile = (props) => <img src="/assets/icons/editfile.svg" />;

export const UploadFileIcon = (props) => (
  <img src="/assets/icons/uploadFile.svg" />
);

export const DolarIcon = (props) => (
  <span role="img" aria-label="edit" className="anticon anticon-edit">
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.00390625 10.332H16.0039V0.332031H0.00390625V10.332ZM14.0039 3.78203V6.89203C13.4039 7.23824 12.9043 7.73447 12.5539 8.33203H3.43391C3.0891 7.73697 2.59655 7.24098 2.00391 6.89203V3.78203C2.60502 3.4329 3.10478 2.93315 3.45391 2.33203H12.5539C12.903 2.93315 13.4028 3.4329 14.0039 3.78203ZM6.50391 5.33203C6.50391 6.43703 7.17591 7.33203 8.00391 7.33203C8.83191 7.33203 9.50391 6.43703 9.50391 5.33203C9.50391 4.22703 8.83191 3.33203 8.00391 3.33203C7.17591 3.33203 6.50391 4.22703 6.50391 5.33203Z"
      />
    </svg>
  </span>
);
export const DolarRefundIcon = (props) => (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4608 1.42196L10.3808 3.51196H12.5508C14.6725 3.51196 16.7073 4.35482 18.2076 5.85511C19.7079 7.3554 20.5508 9.39023 20.5508 11.512H18.5508C18.5508 9.92066 17.9186 8.39454 16.7934 7.26932C15.6682 6.1441 14.1421 5.51196 12.5508 5.51196H10.3808L12.4708 7.60196L11.0508 9.01196L6.55078 4.51196L7.96078 3.10196L11.0508 0.0119629L12.4608 1.42196ZM0.550781 10.512V20.512H16.5508V10.512H0.550781ZM2.55078 17.072V13.962C3.1519 13.6128 3.65165 13.1131 4.00078 12.512H13.1008C13.4499 13.1131 13.9497 13.6128 14.5508 13.962V17.072C13.9581 17.4209 13.4656 17.9169 13.1208 18.512H4.00078C3.65043 17.9144 3.15076 17.4182 2.55078 17.072ZM8.55078 17.512C9.37878 17.512 10.0508 16.617 10.0508 15.512C10.0508 14.407 9.37878 13.512 8.55078 13.512C7.72278 13.512 7.05078 14.407 7.05078 15.512C7.05078 16.617 7.72278 17.512 8.55078 17.512Z"
      fill="#FEC20C"
    />
  </svg>
);

export const coinIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.8548 4.95452C4.03188 4.60722 4.23509 4.27648 4.46055 3.96588C4.46217 3.9633 4.46406 3.96073 4.46586 3.95833C4.50667 3.90085 4.54891 3.84456 4.59179 3.78901C4.54702 3.84695 4.50306 3.90582 4.46055 3.96588C4.23509 4.27648 4.03188 4.60722 3.8548 4.95452ZM5.33013 2.9708C5.35554 2.94679 5.38085 2.92306 5.40625 2.89952C5.40715 2.89878 5.40787 2.89804 5.40859 2.8974C5.38247 2.92159 5.35626 2.94615 5.33013 2.9708ZM4.88354 13.2436C4.90606 13.2693 4.92867 13.2949 4.95155 13.3203C4.94506 13.313 4.9384 13.3057 4.93191 13.2984C4.91579 13.2803 4.89967 13.2619 4.88354 13.2436Z"
      fill="#FDF6F3"
    />
    <path
      d="M9.43851 1.03516C9.45302 1.0335 9.46761 1.03185 9.48211 1.03019C9.51553 1.02669 9.54877 1.02311 9.582 1.02026C9.53426 1.02449 9.48652 1.02945 9.43851 1.03516Z"
      fill="#CB8263"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.6428 9.71642C17.6428 5.33053 14.2182 1.76976 10 1.76976C5.78185 1.76976 2.35725 5.33053 2.35725 9.71642C2.35725 14.1023 5.78185 17.6631 10 17.6631C14.2182 17.6631 17.6428 14.1023 17.6428 9.71642Z"
      fill="#FFDF26"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4614 9.7171C16.4614 6.00911 13.5661 2.99865 9.99991 2.99865C6.43372 2.99865 3.53833 6.00911 3.53833 9.7171C3.53833 13.4251 6.43372 16.4355 9.99991 16.4355C13.5661 16.4355 16.4614 13.4251 16.4614 9.7171Z"
      fill="#EBB718"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.08394 14.0755C6.26951 15.5194 8.03267 16.4355 9.99991 16.4355C13.5661 16.4355 16.4614 13.4251 16.4614 9.7171C16.4614 7.9466 15.8014 6.33513 14.7233 5.1345C15.6869 6.30808 16.2689 7.83037 16.2689 9.49286C16.2689 13.2008 13.3736 16.2113 9.80738 16.2113C7.94396 16.2113 6.26368 15.3894 5.08394 14.0755Z"
      fill="#F09C04"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4603 9.84277C16.4611 9.80082 16.4614 9.75881 16.4614 9.71667C16.4614 6.00868 13.5661 2.99822 9.99991 2.99822C6.43372 2.99822 3.53833 6.00868 3.53833 9.71667C3.53833 9.75881 3.53874 9.80082 3.53947 9.84277C3.60422 6.19288 6.47416 3.2504 9.99991 3.2504C13.5256 3.2504 16.3955 6.19288 16.4603 9.84277Z"
      fill="#FFF459"
    />
    <g filter="url(#filter0_d_916_2212)">
      <path
        d="M7.36905 7.75977L5.26538 11.9128H5.89201L7.36905 8.94011L7.77188 9.727L8.0852 9.07126L7.36905 7.75977Z"
        fill="#25447A"
      />
      <path
        d="M9.11465 7.80348L7.01098 11.9565H7.63761L9.11465 9.02754L9.51748 9.77072L9.8308 9.15869L9.11465 7.80348Z"
        fill="#2A5C97"
      />
      <path
        d="M8.75658 10.4702L8.44327 11.0822L8.89086 11.9565H9.38321L10.8603 9.02754L11.2631 9.77072L11.5764 9.11497L10.8603 7.80348L9.11465 11.2134L8.75658 10.4702Z"
        fill="#25447A"
      />
      <path
        d="M10.5022 10.4702L10.1889 11.0822L10.6365 11.9565H11.1544L12.6059 9.07126L14.1277 11.9565H14.7543L12.6059 7.80348L10.8603 11.2134L10.5022 10.4702Z"
        fill="#2A5C97"
      />
      <path
        d="M12.2478 10.4265L11.9345 11.0385L12.3821 11.9565H13.0087L12.2478 10.4265Z"
        fill="#25447A"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_916_2212"
        x="0.897977"
        y="5.13932"
        width="18.2238"
        height="12.9311"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.74696" />
        <feGaussianBlur stdDeviation="2.1837" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_916_2212"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_916_2212"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const sortIcon = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 8.70422L5 16.7042" stroke="#222222" stroke-linejoin="round" />
    <path
      d="M5.22361 5.15144L6.71056 8.12534C6.84354 8.3913 6.65014 8.70422 6.35279 8.70422L3.64721 8.70422C3.34986 8.70422 3.15646 8.3913 3.28944 8.12534L4.77639 5.15144C4.86852 4.96718 5.13148 4.96717 5.22361 5.15144Z"
      fill="#222222"
    />
    <path
      d="M5.22361 20.257L6.71056 17.2831C6.84354 17.0171 6.65014 16.7042 6.35279 16.7042L3.64721 16.7042C3.34986 16.7042 3.15646 17.0171 3.28944 17.2831L4.77639 20.257C4.86852 20.4413 5.13148 20.4413 5.22361 20.257Z"
      fill="#222222"
    />
    <path d="M11 8.70422H19" stroke="#222222" stroke-linecap="round" />
    <path d="M11 12.7042H19" stroke="#222222" stroke-linecap="round" />
    <path d="M11 16.7042H19" stroke="#222222" stroke-linecap="round" />
  </svg>
);

export const eyeIcon = (
  <svg
    width="30"
    height="31"
    viewBox="0 0 30 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15.7042" r="4.5" stroke="#222222" />
    <path
      d="M25.4469 14.6329C25.8314 15.1131 26.0237 15.3531 26.0237 15.7042C26.0237 16.0553 25.8314 16.2954 25.4469 16.7755C23.7871 18.8481 19.7352 23.2042 15 23.2042C10.2648 23.2042 6.21291 18.8481 4.55311 16.7755C4.16856 16.2954 3.97629 16.0553 3.97629 15.7042C3.97629 15.3531 4.16856 15.1131 4.55311 14.6329C6.21291 12.5604 10.2648 8.20422 15 8.20422C19.7352 8.20422 23.7871 12.5604 25.4469 14.6329Z"
      stroke="#222222"
    />
  </svg>
);

export const facebookIcon = (isSelct) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 53 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={`${isSelct ? "1" : "0.2"}`}>
      <g filter="url(#filter0_d_953_4189)">
        <path
          d="M26.5 45.5C38.9264 45.5 49 35.4264 49 23C49 10.5736 38.9264 0.5 26.5 0.5C14.0736 0.5 4 10.5736 4 23C4 35.4264 14.0736 45.5 26.5 45.5Z"
          fill="#3B5998"
        />
      </g>
      <path
        d="M31.8715 23.2052H28.8207V34.3821H24.1984V23.2052H22V19.2772H24.1984V16.7353C24.1984 14.9176 25.0618 12.0713 28.8618 12.0713L32.2857 12.0856V15.8984H29.8015C29.394 15.8984 28.821 16.102 28.821 16.9691V19.2809H32.2754L31.8715 23.2052Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_953_4189"
        x="0"
        y="0.5"
        width="53"
        height="53"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_953_4189"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_953_4189"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const appleIcon = (isSelct) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 53 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={`${isSelct ? "1" : "0.2"}`}>
      <g filter="url(#filter0_d_953_4192)">
        <path
          d="M26.5 45.5C38.9264 45.5 49 35.4264 49 23C49 10.5736 38.9264 0.5 26.5 0.5C14.0736 0.5 4 10.5736 4 23C4 35.4264 14.0736 45.5 26.5 45.5Z"
          fill="#111820"
        />
      </g>
      <path
        d="M35.0009 28.2287C34.6957 28.9274 34.3344 29.5706 33.9158 30.1619C33.3452 30.9681 32.8781 31.5261 32.518 31.8359C31.9599 32.3446 31.3619 32.6051 30.7216 32.6199C30.2619 32.6199 29.7075 32.4902 29.0622 32.2273C28.4148 31.9656 27.8198 31.8359 27.2758 31.8359C26.7052 31.8359 26.0933 31.9656 25.4387 32.2273C24.7832 32.4902 24.2551 32.6273 23.8513 32.6409C23.2373 32.6668 22.6252 32.3989 22.0143 31.8359C21.6244 31.4989 21.1367 30.9212 20.5524 30.1027C19.9255 29.2286 19.4101 28.2151 19.0064 27.0596C18.5739 25.8114 18.3572 24.6028 18.3572 23.4327C18.3572 22.0924 18.6494 20.9364 19.2348 19.9677C19.6949 19.1895 20.307 18.5757 21.073 18.1251C21.839 17.6745 22.6667 17.4449 23.5581 17.4302C24.0458 17.4302 24.6854 17.5797 25.4802 17.8735C26.2728 18.1683 26.7817 18.3178 27.0048 18.3178C27.1716 18.3178 27.737 18.143 28.6953 17.7945C29.6016 17.4713 30.3666 17.3375 30.9932 17.3902C32.6912 17.526 33.9669 18.1893 34.8153 19.3844C33.2966 20.2962 32.5454 21.5733 32.5604 23.2116C32.5741 24.4878 33.0413 25.5497 33.9594 26.3929C34.3755 26.7842 34.8402 27.0867 35.3572 27.3015C35.2451 27.6237 35.1267 27.9324 35.0009 28.2287ZM31.1066 13.0427C31.1066 14.0429 30.7378 14.9768 30.0028 15.8412C29.1158 16.8689 28.0429 17.4627 26.8795 17.369C26.8647 17.249 26.8561 17.1227 26.8561 16.99C26.8561 16.0298 27.2779 15.0021 28.027 14.1619C28.401 13.7365 28.8766 13.3828 29.4534 13.1006C30.029 12.8226 30.5734 12.6689 31.0854 12.6426C31.1003 12.7763 31.1066 12.91 31.1066 13.0427Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_953_4192"
        x="0"
        y="0.5"
        width="53"
        height="53"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_953_4192"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_953_4192"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const googleIcon = (isSelct) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 53 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity={`${isSelct ? "1" : "0.2"}`}>
      <g filter="url(#filter0_d_953_4196)">
        <path
          d="M26.5 45.5C38.9264 45.5 49 35.4264 49 23C49 10.5736 38.9264 0.5 26.5 0.5C14.0736 0.5 4 10.5736 4 23C4 35.4264 14.0736 45.5 26.5 45.5Z"
          fill="#E0E0E0"
        />
      </g>
      <path
        d="M26.4999 44.8161C38.7374 44.8161 48.6579 34.8956 48.6579 22.658C48.6579 10.4205 38.7374 0.5 26.4999 0.5C14.2623 0.5 4.3418 10.4205 4.3418 22.658C4.3418 34.8956 14.2623 44.8161 26.4999 44.8161Z"
        fill="white"
      />
      <mask id="mask0_953_4196" x="11" y="7" width="31" height="31">
        <path
          d="M40.8616 20.059H26.8418V25.8721H34.9117C34.1595 29.5651 31.0136 31.6852 26.8418 31.6852C21.9178 31.6852 17.9513 27.7186 17.9513 22.7946C17.9513 17.8706 21.9178 13.904 26.8418 13.904C28.9619 13.904 30.8768 14.6563 32.3813 15.8873L36.7582 11.5104C34.0911 9.18519 30.6716 7.74902 26.8418 7.74902C18.4984 7.74902 11.7963 14.4511 11.7963 22.7946C11.7963 31.1381 18.4984 37.8402 26.8418 37.8402C34.3646 37.8402 41.2035 32.3691 41.2035 22.7946C41.2035 21.9055 41.0668 20.9481 40.8616 20.059Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_953_4196)">
        <path
          d="M10.4287 31.6854V13.9043L22.0548 22.7949L10.4287 31.6854Z"
          fill="#FBBC05"
        />
      </g>
      <mask
        id="mask1_953_4196"
        maskUnits="userSpaceOnUse"
        x="11"
        y="7"
        width="31"
        height="31"
      >
        <path
          d="M40.8616 20.059H26.8418V25.8721H34.9117C34.1595 29.5651 31.0136 31.6852 26.8418 31.6852C21.9178 31.6852 17.9513 27.7186 17.9513 22.7946C17.9513 17.8706 21.9178 13.904 26.8418 13.904C28.9619 13.904 30.8768 14.6563 32.3813 15.8873L36.7582 11.5104C34.0911 9.18519 30.6716 7.74902 26.8418 7.74902C18.4984 7.74902 11.7963 14.4511 11.7963 22.7946C11.7963 31.1381 18.4984 37.8402 26.8418 37.8402C34.3646 37.8402 41.2035 32.3691 41.2035 22.7946C41.2035 21.9055 41.0668 20.9481 40.8616 20.059Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_953_4196)">
        <path
          d="M10.4287 13.9036L22.0548 22.7942L26.8421 18.6225L43.2554 15.9553V6.38086H10.4287V13.9036Z"
          fill="#EA4335"
        />
      </g>
      <mask
        id="mask2_953_4196"
        maskUnits="userSpaceOnUse"
        x="11"
        y="7"
        width="31"
        height="31"
      >
        <path
          d="M40.8616 20.059H26.8418V25.8721H34.9117C34.1595 29.5651 31.0136 31.6852 26.8418 31.6852C21.9178 31.6852 17.9513 27.7186 17.9513 22.7946C17.9513 17.8706 21.9178 13.904 26.8418 13.904C28.9619 13.904 30.8768 14.6563 32.3813 15.8873L36.7582 11.5104C34.0911 9.18519 30.6716 7.74902 26.8418 7.74902C18.4984 7.74902 11.7963 14.4511 11.7963 22.7946C11.7963 31.1381 18.4984 37.8402 26.8418 37.8402C34.3646 37.8402 41.2035 32.3691 41.2035 22.7946C41.2035 21.9055 41.0668 20.9481 40.8616 20.059Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_953_4196)">
        <path
          d="M10.4287 31.6848L30.9454 15.9553L36.3481 16.6392L43.2554 6.38086V39.2076H10.4287V31.6848Z"
          fill="#34A853"
        />
      </g>
      <mask
        id="mask3_953_4196"
        maskUnits="userSpaceOnUse"
        x="11"
        y="7"
        width="31"
        height="31"
      >
        <path
          d="M40.8616 20.059H26.8418V25.8721H34.9117C34.1595 29.5651 31.0136 31.6852 26.8418 31.6852C21.9178 31.6852 17.9513 27.7186 17.9513 22.7946C17.9513 17.8706 21.9178 13.904 26.8418 13.904C28.9619 13.904 30.8768 14.6563 32.3813 15.8873L36.7582 11.5104C34.0911 9.18519 30.6716 7.74902 26.8418 7.74902C18.4984 7.74902 11.7963 14.4511 11.7963 22.7946C11.7963 31.1381 18.4984 37.8402 26.8418 37.8402C34.3646 37.8402 41.2035 32.3691 41.2035 22.7946C41.2035 21.9055 41.0668 20.9481 40.8616 20.059Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_953_4196)">
        <path
          d="M43.2553 39.2082L22.0547 22.7949L19.3191 20.7432L43.2553 13.9043V39.2082Z"
          fill="#4285F4"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_953_4196"
        x="0"
        y="0.5"
        width="53"
        height="53"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_953_4196"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_953_4196"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const loginBasic = (isSelct) => (
  <svg
    width="45"
    height="45"
    viewBox="0 0 53 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_953_4240)" opacity={`${isSelct ? "1" : "0.2"}`}>
      <rect
        x="4"
        y="0.5"
        width="45"
        height="45"
        rx="22.5"
        fill="#FEC20C"
        shape-rendering="crispEdges"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.5 13.375C23.1173 13.375 20.375 16.1173 20.375 19.5C20.375 22.8827 23.1173 25.625 26.5 25.625C29.8827 25.625 32.625 22.8827 32.625 19.5C32.625 16.1173 29.8827 13.375 26.5 13.375ZM18.625 19.5C18.625 15.1508 22.1508 11.625 26.5 11.625C30.8492 11.625 34.375 15.1508 34.375 19.5C34.375 23.8492 30.8492 27.375 26.5 27.375C22.1508 27.375 18.625 23.8492 18.625 19.5Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M26.5001 27.3739C24.5032 27.3739 22.5415 27.8996 20.8122 28.8982C19.0829 29.8967 17.6469 31.3329 16.6486 33.0623C16.407 33.4809 15.8719 33.6243 15.4533 33.3827C15.0348 33.1411 14.8914 32.606 15.133 32.1875C16.2848 30.1919 17.9417 28.5348 19.9371 27.3827C21.9325 26.2305 24.196 25.6239 26.5001 25.6239C28.8043 25.6239 31.0678 26.2305 33.0632 27.3827C35.0585 28.5348 36.7155 30.1919 37.8673 32.1875C38.1089 32.606 37.9655 33.1411 37.547 33.3827C37.1284 33.6243 36.5933 33.4809 36.3517 33.0623C35.3534 31.3329 33.9174 29.8967 32.1881 28.8982C30.4588 27.8996 28.4971 27.3739 26.5001 27.3739Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_953_4240"
        x="0"
        y="0.5"
        width="53"
        height="53"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_953_4240"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_953_4240"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const editIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 15.167H2C1.72667 15.167 1.5 14.9403 1.5 14.667C1.5 14.3937 1.72667 14.167 2 14.167H14C14.2733 14.167 14.5 14.3937 14.5 14.667C14.5 14.9403 14.2733 15.167 14 15.167Z"
      fill="#4B5C71"
    />
    <path
      d="M12.68 2.82043C11.3867 1.5271 10.12 1.49376 8.79334 2.82043L7.98668 3.6271C7.92001 3.69376 7.89334 3.80043 7.92001 3.89376C8.42668 5.66043 9.84001 7.07376 11.6067 7.58043C11.6333 7.5871 11.66 7.59376 11.6867 7.59376C11.76 7.59376 11.8267 7.5671 11.88 7.51376L12.68 6.7071C13.34 6.05376 13.66 5.42043 13.66 4.78043C13.6667 4.12043 13.3467 3.48043 12.68 2.82043Z"
      fill="#4B5C71"
    />
    <path
      d="M10.4067 8.18654C10.2133 8.0932 10.0267 7.99987 9.84668 7.8932C9.70001 7.80654 9.56001 7.7132 9.42001 7.6132C9.30668 7.53987 9.17334 7.4332 9.04667 7.32654C9.03334 7.31987 8.98667 7.27987 8.93334 7.22654C8.71334 7.03987 8.46668 6.79987 8.24668 6.5332C8.22668 6.51987 8.19334 6.4732 8.14668 6.4132C8.08001 6.3332 7.96668 6.19987 7.86668 6.04654C7.78668 5.94654 7.69334 5.79987 7.60668 5.6532C7.50001 5.4732 7.40668 5.2932 7.31334 5.10654C7.19097 4.84431 6.8468 4.76641 6.64218 4.97103L2.89334 8.71987C2.80668 8.80654 2.72668 8.9732 2.70668 9.08654L2.34668 11.6399C2.28001 12.0932 2.40668 12.5199 2.68668 12.8065C2.92668 13.0399 3.26001 13.1665 3.62001 13.1665C3.70001 13.1665 3.78001 13.1599 3.86001 13.1465L6.42001 12.7865C6.54001 12.7665 6.70668 12.6865 6.78668 12.5999L10.5417 8.84484C10.7422 8.64432 10.6669 8.29929 10.4067 8.18654Z"
      fill="#4B5C71"
    />
  </svg>
);

export const closeIcon = (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00004 1.83301C4.32671 1.83301 1.33337 4.82634 1.33337 8.49967C1.33337 12.173 4.32671 15.1663 8.00004 15.1663C11.6734 15.1663 14.6667 12.173 14.6667 8.49967C14.6667 4.82634 11.6734 1.83301 8.00004 1.83301ZM10.24 10.033C10.4334 10.2263 10.4334 10.5463 10.24 10.7397C10.14 10.8397 10.0134 10.8863 9.88671 10.8863C9.76004 10.8863 9.63337 10.8397 9.53337 10.7397L8.00004 9.20634L6.46671 10.7397C6.36671 10.8397 6.24004 10.8863 6.11337 10.8863C5.98671 10.8863 5.86004 10.8397 5.76004 10.7397C5.56671 10.5463 5.56671 10.2263 5.76004 10.033L7.29337 8.49967L5.76004 6.96634C5.56671 6.77301 5.56671 6.45301 5.76004 6.25968C5.95337 6.06634 6.27337 6.06634 6.46671 6.25968L8.00004 7.79301L9.53337 6.25968C9.72671 6.06634 10.0467 6.06634 10.24 6.25968C10.4334 6.45301 10.4334 6.77301 10.24 6.96634L8.70671 8.49967L10.24 10.033Z"
      fill="#4B5C71"
    />
  </svg>
);

type IconCustomProps = {
  iconPath: string;
  className?: string | undefined;
  style?: CSSProperties | undefined;
};

export function IconCustom({ iconPath, ...res }: IconCustomProps) {
  return <img src={iconPath} {...res} />;
}
