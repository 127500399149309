import { LanguageValue } from "@models/global";

export class CategoryModel {
  id?: number;
  names?: LanguageValue[];
  sortOrder?: number;
  isActive?: boolean;
  parentId?: number;

  constructor(parentId?) {
    this.id = undefined;
    this.parentId = parentId;
    this.names = LanguageValue.init([]);
  }

  public static assign(obj) {
    if (!obj) return undefined;

    const newObj = Object.assign(new CategoryModel(), obj);
    newObj.names = LanguageValue.init(obj.names || []);
    return newObj;
  }
}
