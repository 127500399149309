import dayjs from "dayjs";

export interface IAgentDetai {
  birthDate?: string;
}

export class AgentDetailModel implements IAgentDetai {
  birthDate?: string;
  projectUser?: any[];
  public static assign(obj) {
    if (!obj) return undefined;

    const newObj = Object.assign(new AgentDetailModel(), obj);
    newObj.agentAttachment = obj.agentAttachment.map((item) => item?.id);
    newObj.projectUser =
      obj.projectUser === null
        ? []
        : Object.values(
            obj.projectUser.reduce((acc, curr) => {
              const projectId = curr.project.id;
              const unitId = curr.unit?.id;

              if (acc[projectId]) {
                acc[projectId].unitId.push(unitId);
              } else {
                acc[projectId] = { projectId, unitId: [unitId] };
              }

              return acc;
            }, {})
          );
    newObj.birthDate = obj.birthDate ? dayjs(obj.birthDate) : null;
    return newObj;
  }
}
