import type { PagedResultDto } from "../../services/dto/pagedResultDto";
import http from "../httpService";
import { L, LNotification } from "../../lib/abpUtility";
import { notifyError, notifySuccess } from "../../lib/helper";
import { RowAgentModel } from "@models/agent/RowAgent";
import { FileModel } from "@models/File";
import { AgentDetailModel } from "@models/agent/AgentDetailModel";

class AgentService {
  public async create(body: any) {
    const result = await http.post("api/services/app/Agent/Create", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async update(body: any) {
    const result = await http.put("api/services/app/Agent/Update", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async activateOrDeactivate(id: number, isActive) {
    const result = await http.post(
      "api/services/app/Agent/Active",
      { id },
      { params: { isActive } }
    );
    return result.data;
  }

  public async get(id: number, projectId?): Promise<any> {
    if (!id) {
      notifyError(L("Error"), L("EntityNotFound"));
    }

    const result = await http.get("api/services/app/Agent/GetDetail", {
      params: { id, projectId },
    });

    return AgentDetailModel.assign(result.data.result);
  }

  public async getAgentAttachment(userId?: any): Promise<any> {
    const res = await http.get("api/services/app/Agent/GetAgentAttachment", {
      params: userId,
    });
    const { result } = res.data;

    return result;
  }

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/Agent/GetAll", {
      params: params,
    });
    const { result } = res.data;
    result.items = RowAgentModel.assigns(result.items);
    return result;
  }

  public async getAvatar(uniqueId: string): Promise<FileModel[]> {
    if (!uniqueId) {
      notifyError(L("ERROR"), L("ENTITY_NOT_FOUND"));
    }

    const res = await http.get("api/services/app/Documents/GetDocuments", {
      params: { uniqueId },
    });
    const result = res.data.result;
    return FileModel.assigns(result || []);
  }

  public async upload(uniqueId, files: any) {
    console.log(uniqueId);
    const data = new FormData();
    data.append("file", files);

    const result = await http.post("api/Documents/UploadProfile", data, {
      headers: {
        "content-type": "multipart/form-data",
      },
      params: { uniqueId },
    });
    return result.data.result;
  }

  public async getExistEmail(emailAddress: string): Promise<any> {
    const res = await http.get("api/services/app/Agent/GetExistEmail", {
      params: { emailAddress },
    });
    const { result } = res.data;

    if (result) {
      return AgentDetailModel.assign(res.data.result);
    } else {
      return null;
    }
  }
}

export default new AgentService();
