import { LanguageValue } from "@models/global";
import categoryService from "@services/category/categoryService";
import type { PagedResultDto } from "@services/dto/pagedResultDto";
import { action, observable } from "mobx";

class CategoryStore {
  @observable isLoading!: boolean;

  @observable categories!: PagedResultDto<any>;
  @observable editCategory!: any;

  @observable subCategories!: PagedResultDto<any>;
  @observable editSubCategory!: any;

  constructor() {
    this.categories = { items: [], totalCount: 0 };
    this.subCategories = { items: [], totalCount: 0 };
  }

  @action
  async initCategory() {
    this.editCategory = {
      id: 0,
      code: "",
      colour: "",
      description: "",
      names: LanguageValue.init([]),
    };
  }

  @action
  async initSubCategory() {
    this.editSubCategory = {
      id: 0,
      code: "",
      colour: "",
      description: "",
      names: LanguageValue.init([]),
    };
  }

  @action
  async create(body: any) {
    this.isLoading = true;
    this.editCategory = await categoryService
      .create(body)
      .finally(() => (this.isLoading = false));
  }

  @action
  async createSub(body: any) {
    this.isLoading = true;
    this.editSubCategory = await categoryService
      .create(body)
      .finally(() => (this.isLoading = false));
  }

  @action
  async update(body: any) {
    this.isLoading = true;
    await categoryService.update(body);

    this.isLoading = false;
  }

  async updateSub(body: any) {
    this.isLoading = true;
    await categoryService.update(body);

    this.isLoading = false;
  }

  @action
  async activateOrDeactivate(id: number, isActive) {
    await categoryService.activateOrDeactivate(id, isActive);
  }

  @action
  async get(id: number) {
    const result = await categoryService.get(id);
    this.editCategory = result;
  }

  @action
  async getSub(id: number) {
    const result = await categoryService.get(id);
    this.editSubCategory = result;
  }

  @action
  async getAll(params: any) {
    this.isLoading = true;
    const result = await categoryService
      .getAll(params)
      .finally(() => (this.isLoading = false));
    this.categories = result;
  }

  @action
  async getAllSub(params: any) {
    this.isLoading = true;
    const result = await categoryService
      .getAll(params)
      .finally(() => (this.isLoading = false));
    this.subCategories = result;
  }
}

export default CategoryStore;
