import { L, LNotification } from "@lib/abpUtility";
import { notifyError, notifySuccess } from "@lib/helper";
import { RefferalModel } from "@models/referral";
import { PagedResultDto } from "@services/dto/pagedResultDto";
import http from "@services/httpService";

class ReferralService {
  public async create(body: any) {
    const res = await http.post("api/services/app/Referral/Create", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );

    return res.data.result;
  }

  public async update(body: any) {
    const res = await http.put("api/services/app/Referral/Update", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return res.data.result;
  }

  public async activateOrDeactivate(id: number, isActive) {
    const res = await http.post("api/services/app/Referral/Active", {
      id,
      isActive,
    });
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("UPDATE_SUCCESSFULLY")
    );
    return res.data;
  }

  public async get(id: number): Promise<any> {
    if (!id) {
      notifyError(L("ERROR"), L("ENTITY_NOT_FOUND"));
    }

    const res = await http.get("api/services/app/Referral/Get", {
      params: { id },
    });
    const result = RefferalModel.assign(res.data.result);

    return result;
  }

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/Referral/GetAll", { params });

    return res.data.result;
  }

  public async getDataTransaction(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/Referral/GetTransactions", {
      params,
    });
    return res.data.result;
  }
}

export default new ReferralService();
