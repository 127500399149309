import { L, LNotification } from "@lib/abpUtility";
import { notifyError, notifySuccess } from "@lib/helper";

import { AppConfiguration } from "@lib/appconst";
import dayjs from "dayjs";
import http from "@services/httpService";
import { PagedResultDto } from "@services/dto/pagedResultDto";
import { CategoryModel } from "@models/category";
class CateogryService {
  public async create(body: any) {
    const res = await http.post("api/services/app/Category/Create", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    if (res.data.result && res.data.result.birthDate) {
      res.data.result.birthDate = dayjs(res.data.result.birthDate);
    }
    return res.data.result;
  }

  public async update(body: any) {
    const res = await http.post("api/services/app/Category/Update", body);
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return res.data.result;
  }

  // public async delete(id: number) {
  //   const res = await http.delete("api/services/app/Employees/Delete", {
  //     params: { id },
  //   });
  //   return res.data;
  // }

  public async activateOrDeactivate(id: number, isActive) {
    const res = await http.put("api/services/app/Category/Active", null, {
      params: { id, isActive },
    });
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("UPDATE_SUCCESSFULLY")
    );
    return res.data;
  }

  public async get(id: number): Promise<any> {
    if (!id) {
      notifyError(L("ERROR"), L("ENTITY_NOT_FOUND"));
    }

    const res = await http.get("api/services/app/Category/Detail", {
      params: { id },
    });

    return CategoryModel.assign(res.data.result || {});
  }

  public async getAll(params: any): Promise<PagedResultDto<any>> {
    const res = await http.get("api/services/app/Category/Filter", { params });
    const result = res.data.result;
    if (result.items) {
      (result.items || []).forEach((item) => {
        item.profilePictureUrl = item.profilePictureId
          ? `${AppConfiguration.remoteServiceBaseUrl}api/services/app/Profile/GetProfilePictureById?profilePictureId=${item.profilePictureId}`
          : null;
      });
    }

    return res.data.result;
  }
}

export default new CateogryService();
