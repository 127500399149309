import customerService from "@services/customer/customerService";
import type { PagedResultDto } from "@services/dto/pagedResultDto";
import { action, observable } from "mobx";

class CustomerStore {
  @observable isLoading!: boolean;
  @observable customers!: PagedResultDto<any>;
  @observable editCustomer!: any;

  constructor() {
    this.customers = { items: [], totalCount: 0 };
  }

  @action
  async get(id: number) {
    const result = await customerService.get(id);
    this.editCustomer = result;
  }

  @action
  async getAll(params: any) {
    this.isLoading = true;
    const result = await customerService
      .getAll(params)
      .finally(() => (this.isLoading = false));
    this.customers = result;
  }

  @action
  async activateOrDeactivate(id: number, isActive) {
    await customerService.activateOrDeactivate(id, isActive);
  }
}

export default CustomerStore;
