import { action, observable } from "mobx";
import agentService from "@services/agent/agentService";
import type { PagedResultDto } from "@services/dto/pagedResultDto";
import { FileModel } from "@models/File";

class AgentStore {
  @observable isLoading!: boolean;
  @observable avatar!: FileModel[];
  @observable editAgent!: any;
  @observable agents!: PagedResultDto<any>;
  @observable activeSideMenu: boolean = false;
  @observable typeSideMenu: string = "";

  // @action
  // async initAgent() {
  //   this.editAgent = {
  //     name: "",
  //     isActive: true,
  //   };
  // }

  @action
  async create(data: any) {
    const body = {
      ...data,
      isActive: true,
    };
    await agentService.create(body);
  }

  @action
  async update(body: any) {
    await agentService.update(body);
  }

  @action
  async activateOrDeactivate(id: number, isActive) {
    await agentService.activateOrDeactivate(id, isActive);
  }

  @action
  async get(id: number, projectId?) {
    const result = await agentService.get(id, projectId);
    this.editAgent = result;
  }

  @action
  async getAll(params: any) {
    const result = await agentService.getAll(params);
    this.agents = result;
  }
  @action
  async getAvatar(uniqueId: string) {
    const result = await agentService.getAvatar(uniqueId);

    if (result?.length > 0) {
      this.avatar = result;
    } else {
      this.avatar = [];
    }
  }

  async upload(uniqueId, files) {
    await agentService.upload(uniqueId, files);
  }

  @action
  onOpenSideMenu(isActive: boolean) {
    this.activeSideMenu = isActive;
  }

  @action
  onChangeTab(val: string) {
    this.typeSideMenu = val || "";
  }

  @action
  async getExistEmail(emailAddress: string) {
    const result = await agentService.getExistEmail(emailAddress);
    this.editAgent = result;
  }
}

export default AgentStore;
