import dayjs from "dayjs";

export class RefferalModel {
  id?: string;
  startDate?: string;
  endDate?: string;

  public static assign(obj) {
    if (!obj) return undefined;

    const newObj = Object.assign(new RefferalModel(), obj);
    newObj.startDate = obj.startDate ? dayjs(obj.startDate) : null;
    newObj.endDate = obj.endDate ? dayjs(obj.endDate) : null;
    return newObj;
  }

  public static assigns<T>(objs) {
    const results: RefferalModel[] = [];
    objs.forEach((item) => results.push(this.assign(item)));
    return results;
  }
}
