import { NotificationTemplateDetailModel } from "@models/notificationTemplate";
import type { PagedResultDto } from "@services/dto/pagedResultDto";
import notifyCampaignService from "@services/notifyCampaign/notifyCampaignService";
import { action, observable } from "mobx";

class NotifyCampaignStore {
  @observable isLoading!: boolean;
  @observable notifyCampaigns!: PagedResultDto<any>;
  @observable editTemplate!: NotificationTemplateDetailModel;
  constructor() {
    this.notifyCampaigns = { items: [], totalCount: 0 };
  }

  @action
  async update(body: any) {
    this.isLoading = true;
    await notifyCampaignService.update(body);
    this.isLoading = false;
  }

  @action
  async getTemplate(id: number) {
    const result = await notifyCampaignService.getTemplate(id);
    this.editTemplate = result;
  }
}

export default NotifyCampaignStore;
