import { L, LNotification } from "@lib/abpUtility";
import { notifyError, notifySuccess } from "@lib/helper";
import http from "@services/httpService";
import { NotificationTemplateDetailModel } from "@models/notificationTemplate";
class NotifyCampaignService {
  public async update(body: any) {
    const result = await http.put(
      "api/services/app/TemplateNotify/Update",
      body
    );
    notifySuccess(
      LNotification("SUCCESS"),
      LNotification("SAVING_SUCCESSFULLY")
    );
    return result.data.result;
  }

  public async getTemplate(id: number): Promise<any> {
    if (!id) {
      notifyError(L("ERROR"), L("ENTITY_NOT_FOUND"));
    }

    const result = await http.get(
      "api/services/app/TemplateNotify/GetTemplate",
      {
        params: { id },
      }
    );
    return NotificationTemplateDetailModel.assign(result.data.result || {});
  }
}

export default new NotifyCampaignService();
