import type { PagedResultDto } from "@services/dto/pagedResultDto";
import referralService from "@services/referral/referralService";
import { action, observable } from "mobx";

class ReferralStore {
  @observable isLoading!: boolean;
  @observable referrals!: PagedResultDto<any>;
  @observable editReferral!: any;
  @observable transctions!: PagedResultDto<any>;

  constructor() {
    this.referrals = { items: [], totalCount: 0 };
    this.transctions = { items: [], totalCount: 0 };
  }

  @action
  async create(body: any) {
    this.isLoading = true;
    this.editReferral = await referralService
      .create(body)
      .finally(() => (this.isLoading = false));
  }

  @action
  async update(body: any) {
    this.isLoading = true;
    await referralService.update(body).finally(async () => {
      this.isLoading = false;
    });
  }

  @action
  async activateOrDeactivate(id: number, isActive) {
    await referralService.activateOrDeactivate(id, isActive);
  }

  @action
  async get(id: number) {
    const result = await referralService.get(id);
    this.editReferral = result;
  }

  @action
  async getAll(params: any) {
    this.isLoading = true;
    const result = await referralService
      .getAll(params)
      .finally(() => (this.isLoading = false));
    this.referrals = result;
  }

  @action
  async getDataTransaction(params: any) {
    this.isLoading = true;
    const result = await referralService
      .getDataTransaction(params)
      .finally(() => (this.isLoading = false));
    this.transctions = result;
  }
}

export default ReferralStore;
